function orderData(arr, key, order) {
    console.log(arr, key, order)
    return arr.sort((a, b) => {
        const valA = a[key] || '';
        const valB = b[key] || '';

        if (typeof valA === 'string' && typeof valB === 'string') {
            if (order % 2 === 1) {
                return valA.localeCompare(valB);
            }
            return valB.localeCompare(valA);
        }

        if (order % 2 === 1) {
            return valA - valB;
        }
        return valB - valA;
    });
}


export default orderData;
