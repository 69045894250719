import React, { useState } from 'react';


const SysRules = () => {

    const [isAccepted, setIsAccepted] = useState(localStorage.getItem('isAccepted') === 'true' ? true : false);
    const acceptRules = () => {
        localStorage.setItem('isAccepted', 'true');
        setIsAccepted(true);
    }
    const [buttonAccept, setButtonAccept] = useState(false);
    const lastPage = () => {
        setButtonAccept(true);
        setPage(12);
    }
    const [page, setPage] = useState(1);
    return !isAccepted ? (
        <div>
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 9998
                }}
            />
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '80%',
                    height: '100%',
                    backgroundColor: 'white',
                    zIndex: 9999,
                    borderRadius: '5px',
                    border: '5px solid rgb(24, 144, 255)',
                    borderBottom: 'none'
                }}
                tabIndex={0}
            >

                <div style={{ position: "relative", height: "100%" }}>
                    <div
                        style={{ height: "100%", overflow: "auto", scrollbarWidth: "none", msOverflowStyle: "none", '::-webkit-scrollbar': { display: 'none' } }}
                        onScroll={(e) => {
                            const element = e.target;
                            const isAtBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1;
                            const button = document.getElementById('accept-button');
                            if (button) {
                                setButtonAccept(true);
                            }
                        }}
                    >
                        <embed
                            key={page}
                            src={`/termos.pdf#view=FitH&scrollbar=0&toolbar=0&navpanes=0&page=${page}`}
                            type="application/pdf" 
                            width="100%"
                            height="100%"
                            style={{ overflow: 'hidden' }}                         
                        >
                        </embed>
                    </div>
                    <button
                        id="accept-button"
                        className="btn btn-primary mb-3"
                        style={{
                            cursor: 'pointer',
                            zIndex: 10000,
                            position: 'absolute',
                            bottom: '20px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            display: buttonAccept ? 'none' : 'block'
                        }}
                        type="button"
                        onClick={lastPage}
                    >&#8595;</button>
                    <button
                        id="accept-button"
                        className="btn btn-primary mb-3"
                        style={{
                            cursor: 'pointer',
                            zIndex: 10000,
                            position: 'absolute',
                            bottom: '20px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            display: buttonAccept ? 'block' : 'none'
                        }}
                        type="button"
                        onClick={acceptRules}
                    >
                        Li e aceito os termos de uso
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default SysRules;