import React, { useState } from "react";
import Mapa from "pages/Mapas/mapa";
import Input from "components/shared/Inputs/Inputs";
import Button from "components/shared/Button/Button";
import {
  Col, Container, Row, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Table
} from "reactstrap";
import api from "../../services/api";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../../services/Auth.service";
import validator from 'validator';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import ReactInputMask from "react-input-mask";

const CondominioCadastro = () => {
  let navigate = useNavigate();
  const initialState = {
    nome: "",
    sobrenome: "",
    email: "",
    senha: ""
  };





  const [coordenadas, setcoordenadas] = useState(['', '']);
  const [coordenadaMouse, setcoordenadaMouse] = useState(['', '']);

  const handlecoordenadas = (valor) => {
    setcoordenadaMouse(valor);

  };
  const salvarCoordenada = () => {
    setcoordenadas(coordenadaMouse);
    toggle();
    console.log(coordenadaMouse)
  }





  const { id } = useParams();

  const [adress, setAdress] = useState({})
  const [company, setCompany] = useState({})


  const [imovel, setImovel] = useState({})
  const [modal, setmodal] = useState(false);

  const toggle = () => {
    setmodal(!modal);
  };


  const user = AuthService.getCurrentUser();

  let checkempresa = null;
  if (user != null) {
    if (user.empresa[0] != undefined) {
      checkempresa = user.empresa[0].OID_EMPRESA
    }
  }

  if (id) {
    checkempresa = id;
  }


  const { register, setValue, getValues,reset  } = useForm();

  const handleLatitudeChange = (e) => {
    setcoordenadas(['', ''])
  };

  const handleLongitudeChange = (e) => {
    reset({ LONGITUDE: '' });
  };

  // const onChangeHandler = (prop) => (event) => {
  //   setRegister({ ...register, [prop]: event.target.value });
  // };

  const errorAlert = (e) => {
    Swal.fire({
      timer: 200000,
      text: e,
    });
  }


  const checkCep = (e) => {
    const cep = e.target.value.replace(/\D/g, '');
    if (cep.length == 8) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`).then(res => res.json()).then(data => {
        setValue('DS_LOGRADOURO', data.logradouro)
        setValue('DS_COMPLEMENTO', data.complemento)
        setValue('DS_BAIRRO', data.bairro)
        setValue('DS_CIDADE', data.localidade)
        setValue('DS_ESTADO', data.uf)


      })
    }
  }

  const submitHandler = (event) => {

    event.preventDefault();
    let error = 0
    let ida = getValues("CD_IMOVEL")
    let raz = getValues("DS_RAZAO_SOCIAL")
    let leitura = getValues("NR_DIA_LEITURA") != ""? getValues("NR_DIA_LEITURA") : null

    const addressInfo = getValues(["NR_CEP", "DS_LOGRADOURO", "DS_BAIRRO", "NR_NUMERO", "DS_CIDADE", "DS_ESTADO"])
    let counter = 0

    addressInfo.forEach(info => {
      if (!info) {
        counter++
      }
    })
    if(leitura != null && leitura != ""){
      if(leitura > 31 || leitura < 1){
        errorAlert("O dia de leitura deve ser um número entre 1 e 31")
        error++
      }
    }
    if (counter == 0) {
      setAdress({
        "NR_CEP": addressInfo[0],
        "DS_LOGRADOURO": addressInfo[1],
        "DS_BAIRRO": addressInfo[2],
        "NR_NUMERO": addressInfo[3].replace(/\D/g, ''),
        "DS_CIDADE": addressInfo[4],
        "DS_ESTADO": addressInfo[5],

      })
    } else {
      errorAlert("Favor preencher todos os dados do endereço!")
      error++
    }

    if (raz.length < 3) {
      errorAlert("Favor Preencher Razão Social Corretamente")
      error++
    }

  

    const companyData =
    {
      "NR_CNPJ": getValues("NR_CNPJ"),
      "NM_RAZAO_SOCIAL": getValues("DS_RAZAO_SOCIAL"),
      "CD_CEP": getValues("NR_CEP").replace(/\D/g, ''),
      "DS_LOGRADOURO": getValues("DS_LOGRADOURO"),
      "DS_BAIRRO": getValues("DS_BAIRRO"),
      "NR_LOGRADOURO": getValues("NR_NUMERO") ? getValues("NR_NUMERO") : 0,
      "DS_COMPL_LOGR": getValues('DS_COMPLEMENTO') ? getValues('DS_COMPLEMENTO') : 'N/A',
      "NM_CIDADE": getValues("DS_CIDADE"),
      "CD_ESTADO": getValues("DS_ESTADO") ? getValues("DS_ESTADO") : "MG",
      "DS_PAIS": "Brasil",
      "CNPJCHECK": getValues("NR_CNPJ").replace(/\D/g, ''),
      "DS_LOCALIZACAO_LONGITUDE": coordenadas[0],
      "DS_LOCALIZACAO_LATITUDE": coordenadas[1],
      "NR_DIA_LEITURA": leitura
    }
    console.log(companyData)
    if(error == 0){
    api
      .post("/empresa/empresa", companyData)
      .then((res) => {

        console.log(res.data)

        let body = {
          OID_EMPRESA: res.data.OID_EMPRESA,
          CD_IMOVEL: getValues("CD_IMOVEL"),


        };
        console.log(res)
        const idempresa = res.data.OID_EMPRESA;
        // setImovel({
        //   OID_EMPRESA: getValues(res.data[0].OID_EMPRESA)

        // })

        api
          .post("/imovel/imovel", body)
          .then((response) => {
          }).catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              text: "O código do Imóvel já foi cadastrado no nosso Sistema",
            });
          });

      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          text: "Erro",
        });
      });


    Swal.fire({
      icon: "success",
      text: "Condomínio Cadastrado com sucesso!",
      timer: 16000,
    }).then(() => {

      navigate("/condominios");

    });
  }


  };

  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Cadastrar | Condomínio</label>

        {!id ? (
          <div>
            <Button
              icon="bx bx-left-arrow-circle"
              iconPosition="left"
              primaryColor="#f8f7ff"
              hoverColor="#f8f7ff"
              text="Voltar"
              link="/condominios"
              styles={{
                marginLeft: "1.5rem",
                background: "#f8f7ff",
                color: "blue",
              }}
            />
          </div>
        ) : (
          <div>

            <Link
              color=""
              className="btn "

              to={`/condominios/`}

            > <i className="mdi mdi-chevron-left" /> Voltar </Link>
          </div>

        )}
        <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">
            <div className="mb-3 col-5">

              <h6 className="page-title">Informações do Condomínio</h6>

              <div className="form-group">
                <label
                  className=" col-form-label"
                >CNPJ
                </label>
                <ReactInputMask
                  {...register("NR_CNPJ")} //onBlur={checkCnpj}
                  mask="99.999.999/9999-99"
                  className="form-control form-control-lg"
                  type="text"
                  defaultValue=""


                />
              </div>
            </div>
            <div className="mb-3 col-5">
              <div className="form-group">
                <label
                  className="col-md-12col-form-label"
                >Razão Social *
                </label>
                <input
                  {...register("DS_RAZAO_SOCIAL")}
                  className="form-control form-control-lg"
                  type="text"
                  defaultValue=""


                />
              </div>
            </div>
            <div className="mb-3 col-5">

              <div className="form-group">
                <label
                  className="col-md-12col-form-label"
                >Código do Imóvel do Condomínio
                </label>
                <input
                  {...register("CD_IMOVEL")}

                  className="form-control form-control-lg"
                  type="text"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="mb-3 col-5">

            <div className="form-group">
              <label
                className="col-md-12col-form-label"
              >Dia de leitura
              </label>
              <input
                {...register("NR_DIA_LEITURA")}

                className="form-control form-control-lg"
                type="number"
                defaultValue=""
              />
            </div>
            </div>

            <div className="form-horizontal mt-4 av-valid">

              <h6 className="page-title">Informações de endereço do condomínio</h6>


              <label
                className="col-md-12col-form-label"
              >CEP*
              </label>
              <ReactInputMask
                {...register("NR_CEP")} onBlur={checkCep}
                mask="99999-999"
                className="form-control form-control-lg"
                type="text"
                defaultValue=""
              />
              <Row>
                <Col lg={6}>
                  <label
                    className="col-md-12col-form-label"
                  >Latitude*
                  </label>
                  <input
                    {...register("LATITUDE")}
                    className="form-control form-control-lg w-100"
                    type="number"
                    value={coordenadas[0]}
                    onChange={handleLatitudeChange}
                    defaultValue=""
                  />
                </Col>
                <Col lg={6}>
                  <label
                    className="col-md-12col-form-label"
                  >Longitude*
                  </label>
                  <input
                    {...register("LONGITUDE")}
                    className="form-control form-control-lg w-100"
                    type="number"
                    value={coordenadas[1]}
                    onChange={handleLatitudeChange}
                    defaultValue=""
                    maxLength={10}
                  />
                </Col>
                <div onClick={toggle}><br></br>
                  <button
                    type="button"
                    color="primary"
                    className="btn-rounded  mb-2 me-2"
                  // onClick={handleOrderClicks}
                  >
                    Abrir Mapa
                  </button>
                  <br></br><br></br>
                </div>
              </Row>
              <label
                className="col-md-12col-form-label"
              >Logradouro*
              </label>
              <input
                {...register("DS_LOGRADOURO")}
                className="form-control form-control-lg"
                type="text"
                defaultValue=""
              />
              <Row>
                <Col lg={6}>
                  <label
                    className="col-md-12col-form-label"
                  >Bairro*
                  </label>
                  <input
                    {...register("DS_BAIRRO")}
                    className="form-control form-control-lg w-100"
                    type="text"
                    defaultValue=""
                  />
                </Col>
                <Col lg={6}>
                  <label
                    className="col-md-12col-form-label"
                  >Número*
                  </label>
                  <input
                    {...register("NR_NUMERO")}
                    className="form-control form-control-lg w-100"
                    type="number"
                    defaultValue=""
                    maxLength={10}
                  />
                </Col>
              </Row>
              <label
                className="col-md-12col-form-label"
              >Complemento
              </label>
              <input
                {...register("DS_COMPLEMENTO")}
                className="form-control form-control-lg"
                type="text"
                defaultValue=""
              />
              <label
                className="col-md-12col-form-label"
              >Cidade*
              </label>
              <input
                {...register("DS_CIDADE")}
                className="form-control form-control-lg"
                type="text"
                defaultValue=""

              />
              <label
                className="col-md-12col-form-label"
              >Estado*
              </label>
              <input
                {...register("DS_ESTADO")}
                className="form-control form-control-lg"
                type="text"
                defaultValue=""
                maxLength="2"
                placeholder="MG"

              />
              <label
                className="col-md-12col-form-label"
              >País*
              </label>
              <input
                {...register("DS_PAIS")}
                className="form-control form-control-lg"
                type="text"
                defaultValue="Brasil"

              />

              <div className="mb-3">
              </div>



            </div>

            <div className="row">
              <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
                <Button
                  icon="ti-user"
                  text="Cadastrar"
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                />
              </div>
            </div>
          </div>
        </form>
      </div>



      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        style={{
          width: "100vw",
          height: "100vh",
          maxWidth: "100vw",
          maxHeight: "100vh",
          margin: 0
        }}
        toggle={() => {
          setmodal(!modal);
        }}
      >
          <button
    onClick={() => setmodal(false)}
    style={{
      position: "absolute",
      top: "10px",
      left: "10px",
      backgroundColor: "transparent",
      border: "none",
      fontSize: "40px",
      cursor: "pointer",
      zIndex: 800 // Ensure it appears above other content
    }}
  >
    &times;
  </button>

        <ModalBody onDoubleClick={salvarCoordenada}>
          <Mapa Coordenadas={handlecoordenadas} ></Mapa>
        </ModalBody>

      </Modal>
    </React.Fragment>
  );
};

export default CondominioCadastro;
