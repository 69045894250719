import React, { useState, useEffect } from 'react';
import api from 'services/api';

const Leitura = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        api.get('/empresa/').then((response) => {
            let arr = [];
            arr = formatArray(response.data);
            console.log(arr)
            setData(arr)
        });
    }, []);

    function verificaleitura(diaLeitura) {
        const hoje = new Date();
        const diaAtual = hoje.getDate();

        let diasRestantes;
        if (diaLeitura >= diaAtual) {
            diasRestantes = diaLeitura - diaAtual;
        } else {
            const ultimoDiaMes = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0).getDate();
            diasRestantes = (ultimoDiaMes - diaAtual) + parseInt(diaLeitura);
        }

        if (diasRestantes <= 2) {
            return "red";
        } else if (diasRestantes > 2 && diasRestantes < 5) {
            return "green";
        } else {
            return "none";
        }
    }
    const formatArray = (data) => {
        let arr = [];
        let leituraStatus;
        data.map((elem) => {
            leituraStatus = verificaleitura(elem.NR_DIA_LEITURA)
            if (leituraStatus != "none") {
                arr.push({
                    id: elem.OID_EMPRESA,
                    cnpj: elem.NR_CNPJ,
                    razao: elem.NM_RAZAO_SOCIAL,
                    cep: elem.CD_CEP,
                    telefone: elem.NR_TELEFONE,
                    endereco: elem.DS_LOGRADOURO + ", " + elem.NR_LOGRADOURO + ", " + elem.DS_BAIRRO + ".",
                    checked: elem.TP_ENVIO === "S" ? true : false,
                    log: elem.DS_LOGRADOURO,
                    nr: elem.NR_LOGRADOURO,
                    bairro: elem.DS_BAIRRO,
                    diaLeitura: elem?.NR_DIA_LEITURA ? parseInt(elem.NR_DIA_LEITURA) : 0,
                    leituraStatus: leituraStatus

                });
            }
        });

        arr.sort((a, b) => a.diaLeitura - b.diaLeitura);
        return arr;
    };



    return (
        <div style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#fff',
            padding: '2px',
            boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
            textAlign: 'center',
            zIndex: 9999, // Aumentado para sobrepor outros elementos
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }}>

            <div style={{
                display: 'inline-block',
                animation: 'scroll 20s linear infinite',
                paddingLeft: '100%'
            }}>
                <style>
                    {`
                        @keyframes scroll {
                            0% { transform: translateX(0); }
                            100% { transform: translateX(-100%); }
                        }
                    `}
                </style><span><strong>Próximas Leituras:  </strong></span>
                {data.map((item, index) => (
                    <span key={item.id} style={{ marginRight: '10px' }}>
                        {item.razao} - Dia:<span style={{ color: item.leituraStatus }}>{item.diaLeitura}</span>
                        {index < data.length - 1 ? '    ' : ''}
                    </span>
                ))}
            </div>
        </div>
    )
}
export default Leitura;