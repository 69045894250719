import React from "react"
import { Container, Row, Col } from "reactstrap"
import SysRules from "../shared/Util/rules"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Syskeeper.</Col>
         
          </Row>
        </Container>
      </footer>
      <SysRules/>
    </React.Fragment>
  )
}

export default Footer
